// === EXTRAS ===
export const GET_CITIES_BEGIN = 'GET_CITIES_BEGIN';
export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS';
export const GET_CITIES_ERROR = 'GET_CITIES_ERROR';

// === Auth ===
export const EMPLOYEE_SIGN_IN_BEGIN = 'EMPLOYEE_SIGN_IN_BEGIN';
export const EMPLOYEE_SIGN_IN_SUCCESS = 'EMPLOYEE_SIGN_IN_SUCCESS';
export const EMPLOYEE_SIGN_IN_ERROR = 'EMPLOYEE_SIGN_IN_ERROR';
export const EMPLOYEE_SIGN_OUT = 'EMPLOYEE_SIGN_OUT';

// === Employee ===
export const GET_EMPLOYEES_BEGIN = 'GET_EMPLOYEES_BEGIN';
export const GET_EMPLOYEES_SUCCESS = 'GET_EMPLOYEES_SUCCESS';
export const GET_EMPLOYEES_ERROR = 'GET_EMPLOYEES_ERROR';
export const ADD_EMPLOYEE_TO_TABLE = 'ADD_EMPLOYEE_TO_TABLE';
export const UPDATE_EMPLOYEE_ON_TABLE = 'UPDATE_EMPLOYEE_ON_TABLE';
export const DELETE_EMPLOYEE_FROM_TABLE = 'DELETE_EMPLOYEE_FROM_TABLE';
export const GET_EMPLOYEE_BY_ID_BEGIN = 'GET_EMPLOYEE_BY_ID_BEGIN';
export const GET_EMPLOYEE_BY_ID_SUCCESS = 'GET_EMPLOYEE_BY_ID_SUCCESS';
export const GET_EMPLOYEE_BY_ID_ERROR = 'GET_EMPLOYEE_BY_ID_ERROR';
export const FIND_EMPLOYEES_BEGIN = 'FIND_EMPLOYEES_BEGIN';
export const FIND_EMPLOYEES_SUCCESS = 'FIND_EMPLOYEES_SUCCESS';
export const FIND_EMPLOYEES_ERROR = 'FIND_EMPLOYEES_ERROR';
export const SELECT_EMPLOYEES = 'SELECT_EMPLOYEES';
export const UPDATE_SELECTED_EMPLOYEE_DATA = 'UPDATE_SELECTED_EMPLOYEE_DATA';
export const DELETE_SELECTED_EMPLOYEE_DATA = 'DELETE_SELECTED_EMPLOYEE_DATA';
export const SET_SENDER_EMPLOYEE = 'SET_SENDER_EMPLOYEE';
export const SET_RECEIVER_EMPLOYEE = 'SET_RECEIVER_EMPLOYEE';
export const CREATE_EMPLOYEE_BEGIN = 'CREATE_EMPLOYEE_BEGIN';
export const CREATE_EMPLOYEE_SUCCESS = 'CREATE_EMPLOYEE_SUCCESS';
export const CREATE_EMPLOYEE_ERROR = 'CREATE_EMPLOYEE_ERROR';
export const UPDATE_EMPLOYEE_BEGIN = 'UPDATE_EMPLOYEE_BEGIN';
export const UPDATE_EMPLOYEE_SUCCESS = 'UPDATE_EMPLOYEE_SUCCESS';
export const UPDATE_EMPLOYEE_ERROR = 'UPDATE_EMPLOYEE_ERROR';
export const DELETE_EMPLOYEE_BEGIN = 'DELETE_EMPLOYEE_BEGIN';
export const DELETE_EMPLOYEE_SUCCESS = 'DELETE_EMPLOYEE_SUCCESS';
export const DELETE_EMPLOYEE_ERROR = 'DELETE_EMPLOYEE_ERROR';
export const SET_EMPLOYEES_FILTER_KEYS = 'SET_EMPLOYEES_FILTER_KEYS';

// === Customer ===
export const FIND_CUSTOMERS_BEGIN = 'FIND_CUSTOMERS_BEGIN';
export const FIND_CUSTOMERS_SUCCESS = 'FIND_CUSTOMERS_SUCCESS';
export const FIND_CUSTOMERS_ERROR = 'FIND_CUSTOMERS_ERROR';
export const SELECT_CUSTOMERS = 'SELECT_CUSTOMERS';
export const UPDATE_SELECTED_CUSTOMER_DATA = 'UPDATE_SELECTED_CUSTOMER_DATA';
export const DELETE_SELECTED_CUSTOMER_DATA = 'DELETE_SELECTED_CUSTOMER_DATA';
export const SET_SENDER_CUSTOMER = 'SET_SENDER_CUSTOMER';
export const SET_RECEIVER_CUSTOMER = 'SET_RECEIVER_CUSTOMER';
export const CREATE_CUSTOMER_BEGIN = 'CREATE_CUSTOMER_BEGIN';
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS';
export const CREATE_CUSTOMER_ERROR = 'CREATE_CUSTOMER_ERROR';
export const UPDATE_CUSTOMER_BEGIN = 'UPDATE_CUSTOMER_BEGIN';
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_ERROR = 'UPDATE_CUSTOMER_ERROR';
export const DELETE_CUSTOMER_BEGIN = 'DELETE_CUSTOMER_BEGIN';
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS';
export const DELETE_CUSTOMER_ERROR = 'DELETE_CUSTOMER_ERROR';
export const SHOW_CUSTOMERS_BY_NUMBER_BEGIN = 'SHOW_CUSTOMERS_BY_NUMBER_BEGIN';
export const SHOW_CUSTOMERS_BY_NUMBER_SUCCESS = 'SHOW_CUSTOMERS_BY_NUMBER_SUCCESS';
export const SHOW_CUSTOMERS_BY_NUMBER_ERROR = 'SHOW_CUSTOMERS_BY_NUMBER_ERROR';
export const SET_CUSTOMERS_FILTER_KEYS = 'SET_CUSTOMERS_FILTER_KEYS';


// === Station ===
export const GET_STATIONS_BEGIN = 'GET_STATIONS_BEGIN';
export const GET_STATIONS_SUCCESS = 'GET_STATIONS_SUCCESS';
export const GET_STATIONS_ERROR = 'GET_STATIONS_ERROR';
export const SELECT_STATIONS = 'SELECT_STATIONS';
export const UPDATE_SELECTED_STATION_DATA = 'UPDATE_SELECTED_STATION_DATA';
export const DELETE_SELECTED_STATION_DATA = 'DELETE_SELECTED_STATION_DATA';
export const CREATE_STATION_BEGIN = 'CREATE_STATION_BEGIN';
export const CREATE_STATION_SUCCESS = 'CREATE_STATION_SUCCESS';
export const CREATE_STATION_ERROR = 'CREATE_STATION_ERROR';
export const UPDATE_STATION_BEGIN = 'UPDATE_STATION_BEGIN';
export const UPDATE_STATION_SUCCESS = 'UPDATE_STATION_SUCCESS';
export const UPDATE_STATION_ERROR = 'UPDATE_STATION_ERROR';
export const DELETE_STATION_BEGIN = 'DELETE_STATION_BEGIN';
export const DELETE_STATION_SUCCESS = 'DELETE_STATION_SUCCESS';
export const DELETE_STATION_ERROR = 'DELETE_STATION_ERROR';
export const ADD_STATION_TO_TABLE = 'ADD_STATION_TO_TABLE';
export const UPDATE_STATION_ON_TABLE = 'UPDATE_STATION_ON_TABLE';
export const DELETE_STATION_FROM_TABLE = 'DELETE_STATION_FROM_TABLE';


// === Package ===
export const FIND_PACKAGES_BEGIN = 'FIND_PACKAGES_BEGIN';
export const FIND_PACKAGES_SUCCESS = 'FIND_PACKAGES_SUCCESS';
export const FIND_PACKAGES_ERROR = 'FIND_PACKAGES_ERROR';
export const FIND_PACKAGES_BY_BARCODE_SUCCESS = 'FIND_PACKAGES_BY_BARCODE_SUCCESS';
export const FIND_ADVANCED_PACKAGES_BEGIN = 'FIND_ADVANCED_PACKAGES_BEGIN';
export const FIND_ADVANCED_PACKAGES_SUCCESS = 'FIND_ADVANCED_PACKAGES_SUCCESS';
export const FIND_ADVANCED_PACKAGES_ERROR = 'FIND_ADVANCED_PACKAGES_ERROR';
export const SELECT_PACKAGES = 'SELECT_PACKAGES';
export const SELECT_ADVANCED_PACKAGES = 'SELECT_ADVANCED_PACKAGES';
export const UPDATE_SELECTED_PACKAGE_DATA = 'UPDATE_SELECTED_PACKAGE_DATA';
export const DELETE_SELECTED_PACKAGE_DATA = 'DELETE_SELECTED_PACKAGE_DATA';
export const CREATE_PACKAGE_BEGIN = 'CREATE_PACKAGE_BEGIN';
export const CREATE_PACKAGE_SUCCESS = 'CREATE_PACKAGE_SUCCESS';
export const CREATE_PACKAGE_ERROR = 'CREATE_PACKAGE_ERROR';
export const UPDATE_PACKAGE_BEGIN = 'UPDATE_PACKAGE_BEGIN';
export const UPDATE_PACKAGE_SUCCESS = 'UPDATE_PACKAGE_SUCCESS';
export const UPDATE_PACKAGE_ERROR = 'UPDATE_PACKAGE_ERROR';
export const DELETE_PACKAGE_BEGIN = 'DELETE_PACKAGE_BEGIN';
export const DELETE_PACKAGE_SUCCESS = 'DELETE_PACKAGE_SUCCESS';
export const DELETE_PACKAGE_ERROR = 'DELETE_PACKAGE_ERROR';
export const ADD_COURIER_TO_PACKAGES = 'ADD_COURIER_TO_PACKAGES';
export const CHANGE_PACKAGE_STATE_BEGIN = 'CHANGE_PACKAGE_STATE_BEGIN';
export const CHANGE_PACKAGE_STATE_SUCCESS = 'CHANGE_PACKAGE_STATE_SUCCESS';
export const CHANGE_PACKAGE_STATE_ERROR = 'CHANGE_PACKAGE_STATE_ERROR';
export const TRACK_PACKAGE_BEGIN = 'TRACK_PACKAGE_BEGIN';
export const TRACK_PACKAGE_SUCCESS = 'TRACK_PACKAGE_SUCCESS';
export const TRACK_PACKAGE_ERROR = 'TRACK_PACKAGE_ERROR';
export const UPDATE_TRACKED_PACKAGE_DATA = 'UPDATE_TRACKED_PACKAGE_DATA';
export const TRACK_PACKAGE_VIA_CUSTOMER_ID_BEGIN = 'TRACK_PACKAGE_VIA_CUSTOMER_ID_BEGIN';
export const TRACK_PACKAGE_VIA_CUSTOMER_ID_SUCCESS = 'TRACK_PACKAGE_VIA_CUSTOMER_ID_SUCCESS';
export const TRACK_PACKAGE_VIA_CUSTOMER_ID_ERROR = 'TRACK_PACKAGE_VIA_CUSTOMER_ID_ERROR';
export const SET_PACKAGES_FILTER_KEYS = 'SET_PACKAGES_FILTER_KEYS';

// === Extra Selling ===
export const GET_ALL_EXTRA_SELLING_BEGIN = 'GET_ALL_EXTRA_SELLING_BEGIN';
export const GET_ALL_EXTRA_SELLING_SUCCESS = 'GET_ALL_EXTRA_SELLING_SUCCESS';
export const GET_ALL_EXTRA_SELLING_ERROR = 'GET_ALL_EXTRA_SELLING_ERROR';
export const SELECT_ALL_EXTRA_SELLING = 'SELECT_ALL_EXTRA_SELLING';
export const UPDATE_SELECTED_EXTRA_SELLING_DATA = 'UPDATE_SELECTED_EXTRA_SELLING_DATA';
export const DELETE_SELECTED_EXTRA_SELLING_DATA = 'DELETE_SELECTED_EXTRA_SELLING_DATA';
export const CREATE_EXTRA_SELLING_BEGIN = 'CREATE_EXTRA_SELLING_BEGIN';
export const CREATE_EXTRA_SELLING_SUCCESS = 'CREATE_EXTRA_SELLING_SUCCESS';
export const CREATE_EXTRA_SELLING_ERROR = 'CREATE_EXTRA_SELLING_ERROR';
export const UPDATE_EXTRA_SELLING_BEGIN = 'UPDATE_EXTRA_SELLING_BEGIN';
export const UPDATE_EXTRA_SELLING_SUCCESS = 'UPDATE_EXTRA_SELLING_SUCCESS';
export const UPDATE_EXTRA_SELLING_ERROR = 'UPDATE_EXTRA_SELLING_ERROR';
export const DELETE_EXTRA_SELLING_BEGIN = 'DELETE_EXTRA_SELLING_BEGIN';
export const DELETE_EXTRA_SELLING_SUCCESS = 'DELETE_EXTRA_SELLING_SUCCESS';
export const DELETE_EXTRA_SELLING_ERROR = 'DELETE_EXTRA_SELLING_ERROR';
export const ADD_EXTRA_SELLING_TO_TABLE = 'ADD_EXTRA_SELLING_TO_TABLE';
export const UPDATE_EXTRA_SELLING_ON_TABLE = 'UPDATE_EXTRA_SELLING_ON_TABLE';
export const DELETE_EXTRA_SELLING_FROM_TABLE = 'DELETE_EXTRA_SELLING_FROM_TABLE';

// === Checkout ===
export const ADD_TO_CART = 'ADD_TO_CART';
export const ADD_EXTRA_SERVICE_COST = 'ADD_EXTRA_SERVICE_COST';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const PARSE_PAYMENTS_TO_CART = 'PARSE_PAYMENTS_TO_CART';


// === Courier ===
export const FIND_COURIERS_BEGIN = 'FIND_COURIERS_BEGIN';
export const FIND_COURIERS_SUCCESS = 'FIND_COURIERS_SUCCESS';
export const FIND_COURIERS_ERROR = 'FIND_COURIERS_ERROR';
export const SELECT_COURIERS = 'SELECT_COURIERS';
export const UPDATE_SELECTED_COURIER_DATA = 'UPDATE_SELECTED_COURIER_DATA';
export const DELETE_SELECTED_COURIER_DATA = 'DELETE_SELECTED_COURIER_DATA';
export const CREATE_COURIER_BEGIN = 'CREATE_COURIER_BEGIN';
export const CREATE_COURIER_SUCCESS = 'CREATE_COURIER_SUCCESS';
export const CREATE_COURIER_ERROR = 'CREATE_COURIER_ERROR';
export const UPDATE_COURIER_BEGIN = 'UPDATE_COURIER_BEGIN';
export const UPDATE_COURIER_SUCCESS = 'UPDATE_COURIER_SUCCESS';
export const UPDATE_COURIER_ERROR = 'UPDATE_COURIER_ERROR';
export const DELETE_COURIER_BEGIN = 'DELETE_COURIER_BEGIN';
export const DELETE_COURIER_SUCCESS = 'DELETE_COURIER_SUCCESS';
export const DELETE_COURIER_ERROR = 'DELETE_COURIER_ERROR';

export const ORDER_COURIER_BEGIN = 'ORDER_COURIER_BEGIN';
export const ORDER_COURIER_SUCCESS = 'ORDER_COURIER_SUCCESS';
export const ORDER_COURIER_ERROR = 'ORDER_COURIER_ERROR';

export const SET_COURIERS_FILTER_KEYS = 'SET_COURIERS_FILTER_KEYS';

// === Tariff ===
export const GET_TARIFFS_BEGIN = 'GET_TARIFFS_BEGIN';
export const GET_TARIFFS_SUCCESS = 'GET_TARIFFS_SUCCESS';
export const GET_TARIFFS_ERROR = 'GET_TARIFFS_ERROR';
export const SELECT_TARIFFS = 'SELECT_TARIFFS';
export const UPDATE_SELECTED_TARIFF_DATA = 'UPDATE_SELECTED_TARIFF_DATA';
export const DELETE_SELECTED_TARIFF_DATA = 'DELETE_SELECTED_TARIFF_DATA';
export const CREATE_TARIFF_BEGIN = 'CREATE_TARIFF_BEGIN';
export const CREATE_TARIFF_SUCCESS = 'CREATE_TARIFF_SUCCESS';
export const CREATE_TARIFF_ERROR = 'CREATE_TARIFF_ERROR';
export const UPDATE_TARIFF_BEGIN = 'UPDATE_TARIFF_BEGIN';
export const UPDATE_TARIFF_SUCCESS = 'UPDATE_TARIFF_SUCCESS';
export const UPDATE_TARIFF_ERROR = 'UPDATE_TARIFF_ERROR';
export const DELETE_TARIFF_BEGIN = 'DELETE_TARIFF_BEGIN';
export const DELETE_TARIFF_SUCCESS = 'DELETE_TARIFF_SUCCESS';
export const DELETE_TARIFF_ERROR = 'DELETE_TARIFF_ERROR';
export const ADD_TARIFF_TO_TABLE = 'ADD_TARIFF_TO_TABLE';
export const UPDATE_TARIFF_ON_TABLE = 'UPDATE_TARIFF_ON_TABLE';
export const DELETE_TARIFF_FROM_TABLE = 'DELETE_TARIFF_FROM_TABLE';
export const SET_TARIFF_INTERVAL_BEGIN = "SET_TARIFF_INTERVAL_BEGIN"
export const SET_TARIFF_INTERVAL_SUCCESS = "SET_TARIFF_INTERVAL_SUCCESS"
export const SET_TARIFF_INTERVAL_ERROR = "SET_TARIFF_INTERVAL_ERROR"


// === Payment ===
export const CREATE_PAYMENTS_BEGIN = 'CREATE_PAYMENTS_BEGIN';
export const CREATE_PAYMENTS_SUCCESS = 'CREATE_PAYMENTS_SUCCESS';
export const CREATE_PAYMENTS_ERROR = 'CREATE_PAYMENTS_ERROR';
export const FIND_PAYMENTS_BEGIN = 'FIND_PAYMENTS_BEGIN';
export const FIND_PAYMENTS_SUCCESS = 'FIND_PAYMENTS_SUCCESS';
export const FIND_PAYMENTS_ERROR = 'FIND_PAYMENTS_ERROR';
export const FIND_ADVANCED_PAYMENTS_BEGIN = 'FIND_ADVANCED_PAYMENTS_BEGIN';
export const FIND_ADVANCED_PAYMENTS_SUCCESS = 'FIND_ADVANCED_PAYMENTS_SUCCESS';
export const FIND_ADVANCED_PAYMENTS_ERROR = 'FIND_ADVANCED_PAYMENTS_ERROR';
export const SELECT_PAYMENTS = 'SELECT_PAYMENTS';
export const FIND_PAYMENTS_REPORT_BEGIN = 'FIND_PAYMENTS_REPORT_BEGIN';
export const FIND_PAYMENTS_REPORT_SUCCESS = 'FIND_PAYMENTS_REPORT_SUCCESS';
export const FIND_PAYMENTS_REPORT_ERROR = 'FIND_PAYMENTS_REPORT_ERROR';
export const SET_PAYMENTS_FILTER_KEYS = 'SET_PAYMENTS_FILTER_KEYS';
