import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Table } from 'reactstrap';
import Navi from "../common/Navi";
import { useSelector, useDispatch } from 'react-redux';
import SelectInput from "../../../toolbox/SelectInput";
import DatePicker from 'react-datepicker';
import { formatDate, formatPrice, notEmpty } from '../../../../utilities/helpers';
import { useFormik } from "formik";
import "react-datepicker/dist/react-datepicker.css";
import { getReportExcelData } from '../../../../services/paymentService';
import { getStations } from '../../../../redux/actions/stationActions';
import Select from "react-select";

export const ReportExcel = () => {
    const dispatch = useDispatch();
    const auth = useSelector(state => state.authReducer);
    const stations = useSelector(state => state.getStationsReducer);
    useEffect(() => {
        if (stations.length === 0) dispatch(getStations());
    });
    const initialValues = {
        startDate: null,
        endDate: null,
        station_ids: [],
        reportType: ''
    }
    const exportCSV = () => {
        const items = data.map(d => {
            if (d.amount) d.amount = formatPrice('AZN').format(d.amount);
            if (d.price) d.price = formatPrice('AZN').format(d.price);
            if (d.created_date) d.created_date = formatDate(d.created_date);
            return d;
        });
        const replacer = (key, value) => value === null ? '' : value
        const header = Object.keys(items[0]);
        const csv = [
            header.join(','),
            ...items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
        ].join('\r\n');
        download(csv);
    }

    const download = (csv) => {
        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'report.csv';
        hiddenElement.click();
    }
    const [data, setData] = useState([]);

    const {
        handleSubmit,
        handleChange,
        values,
        errors,
        touched,
        setFieldTouched,
        handleBlur,
        setFieldValue,
        isSubmitting,
    } = useFormik({
        initialValues,
        enableReinitialize: true,
        onSubmit: (values, { setSubmitting }) => {
            setSubmitting(true)
            getReportExcelData({...values, station_ids: values.station_ids.map(s => parseInt(s.value,10))}).then(d => {
                setData(d);
                setSubmitting(false);
            }).catch(err => {
                setSubmitting(false)
            });
        },
    });
    return <div>
        <Navi />
        <Container fluid={false}>
            <Row>
                {!auth.currentEmployee.is_operator_admin &&
                    <Col md={12} className="mb-3">
                        <div className="card mb-3">
                            <div className="card-body">
                                <h3>Hesabat Excel</h3>
                                <form onSubmit={handleSubmit}>

                                    <div className="row justify-content-between">
                                        <div className="col-12 col-md-3">
                                            <SelectInput
                                                label="Hesabat tipini"
                                                placeHolder="Hesabat tipini"
                                                name="reportType"
                                                options={[{ slug: 'posPaidPackages', name: 'Qarşı ödəməli bağlamalar' }, { slug: 'payments', name: 'Ödənişlər' }].map((reportType) => ({
                                                    value: reportType.slug,
                                                    text: reportType.name,
                                                }))}
                                                defaultOption="Hesabat tipini seçin"
                                                value={values.reportType}
                                                error={errors.reportType}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                touched={touched.reportType}
                                            />
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <label htmlFor='date-range'>Tarix aralığı</label>
                                            <DatePicker
                                                selectsRange={true}
                                                startDate={values.startDate}
                                                endDate={values.endDate}
                                                id="date-range"
                                                placeholderText='Tarix aralığı seçin'
                                                className="form-control"
                                                onChange={update => {
                                                    setFieldValue('startDate', update[0]);
                                                    setFieldTouched('startDate', true);
                                                    setFieldValue('endDate', update[1]);
                                                    setFieldTouched('endDate', true);
                                                }}
                                                withPortal
                                            />
                                        </div>
                                        <div className="col-12 col-md-3">
                                            <label>Filtr Filial</label>
                                            <Select
                                                name="station_ids"
                                                value={values.station_ids}
                                                isMulti
                                                options={stations.map((station) => ({
                                                    value: station.id,
                                                    label: station.name,
                                                }))}
                                                onChange={options => setFieldValue('station_ids', options)}
                                                onBlur={() => setFieldTouched('station_ids', true)}
                                                classNamePrefix="select"
                                            />
                                        </div>

                                        <div className="col-2 align-self-center" style={{ marginTop: 12 }}>
                                            <button type="submit" className="btn btn-secondary"
                                                disabled={isSubmitting
                                                    || !notEmpty(values.startDate)
                                                    || !notEmpty(values.endDate)
                                                    // || !notEmpty(values.station_id)
                                                    || !notEmpty(values.reportType)}>
                                                <i className={isSubmitting ? 'fa fa-spinner fa-spin' : "fa fa-search"} />
                                                <span className="ml-2">Axtar</span>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Col>}
                {data.length > 0 && <Col size={12}>
                    <div className="card">
                        <div className="card-body">
                            <h3>Hesabat</h3>
                            <Table hover bordered responsive>
                                {data.map(d => {
                                    return <>
                                        {data.indexOf(d) === 0 && <tr className="bg-light">
                                            {Object.keys(d).map(col => (<th key={col}>{col}</th>))}
                                        </tr>}
                                        <tr>
                                            {Object.values(d).map(val => (<td key={val}>{val}</td>))}
                                        </tr>
                                    </>
                                })}
                            </Table>
                        </div>
                        <div className="card-footer">
                            <button className="btn btn-primary" onClick={exportCSV}>
                                Excel cixart
                            </button>
                        </div>
                    </div>
                </Col>}
            </Row>
        </Container>
    </div>
}