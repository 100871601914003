export const Routes = {
    landing: '/',
    franchising:'/franchising',
    features:'/yenilikler',
    individualCustomers:'/ferdi-musteriler',
    businessCustomers:'/biznes-musteriler',
    discounts:'/kampaniyalar',
    internationalShipping:'/xaricden-dasinmalar',
    vacations:'/vakansiyalar',
    signIn: '/employee/sign-in',
    dashboard: '/employee/dashboard',
    customers: '/employee/dashboard/customers',
    packages: '/employee/dashboard/packages',
    checkout: '/employee/dashboard/checkout',
    courier: '/employee/dashboard/courier',
    report: '/employee/dashboard/report',
    reportExcel: '/employee/dashboard/report-excel',
    stations: '/employee/dashboard/stations',
    tariffs: '/employee/dashboard/tariffs',
    employees: '/employee/dashboard/employees',
    allExtraSelling: '/employee/dashboard/all-extra-selling',
    payments: '/employee/dashboard/payments',
    // client pages 
    trackPackagePage:'/track-package'
}