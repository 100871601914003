import React, {Component} from "react";
import Barcode from "../../../toolbox/Barcode";
import {formatDate} from "../../../../utilities/helpers";

class Label extends Component {
    generateLabels() {
        const labels = []
        let i = 0;
        while (i < this.props.pckg.quantity) {
            i++;
            labels.push(<div key={i} style={{ maxWidth: 370}}>
                    <div style={{textAlign: 'center'}}>
                    <Barcode barcode={this.props.pckg.barcode}/>
                    </div>
                    <div className="row">
                        <div className="col-6" style={{borderRight: '1px solid #ddd', fontSize: 30}}>
                            <p>KOD: {this.props.pckg.id}</p>
                        </div>
                        <div className="col-6" style={{fontSize: 30}}>
                            <p>SAY: {i}/{this.props.pckg.quantity}</p>
                        </div>
                    </div>
                    <div style={{borderBottom: '1px solid', marginBottom: 2}}/>
                    <div style={{textAlign: 'center'}}>
                    <p style={{ fontWeight: 'bolder', fontSize: 40}}>{this.props.pckg?.receiver_station.name}</p>
                    </div>
                    <div style={{borderBottom: '1px solid', marginBottom: 2}}/>
                    <div className="row">
                            <div className="col-6">Alan: <b style={{fontSize:"18px"}}>{this.props.pckg.receiver_full_name}</b> </div>
                            <div className="col-6">Çəki: {this.props.pckg.weight} kg</div>
                    </div>
                    <div style={{borderBottom: '1px solid', marginBottom: 2}}/>
                    <div className="row">
                        <div className="col-12">
                            <div>Qeyd: {this.props.pckg.description}</div>
                        </div>
                    </div>
                    <div style={{borderBottom: '1px solid', marginBottom: 2}}/>
                    <div className="row">
                        <div className="col-6">
                            <p><strong>YENİKARGO</strong></p>
                        </div>
                        <div className="col-6 align-self-center">
                            {formatDate(new Date())}
                            </div>
                    </div>
            </div>)
        }
        return labels;
    }

    render() {
        return (
            <div className={this.props.toggled ? 'd-block' : 'd-none'}>
                {this.props.pckg
                    ?
                    <div>
                        {this.generateLabels()}
                    </div>
                    :
                    null}
            </div>
        )
    }
}

export default Label;